// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-posts-js": () => import("./../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-til-framer-motion-lwj-matt-perry-mdx": () => import("./../src/til/framer-motion-lwj-matt-perry.mdx" /* webpackChunkName: "component---src-til-framer-motion-lwj-matt-perry-mdx" */),
  "component---src-til-svg-animation-with-cassie-mdx": () => import("./../src/til/svg-animation-with-cassie.mdx" /* webpackChunkName: "component---src-til-svg-animation-with-cassie-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-now-js": () => import("./../src/pages/now.js" /* webpackChunkName: "component---src-pages-now-js" */),
  "component---src-pages-til-js": () => import("./../src/pages/til.js" /* webpackChunkName: "component---src-pages-til-js" */),
  "component---src-pages-time-machine-js": () => import("./../src/pages/time-machine.js" /* webpackChunkName: "component---src-pages-time-machine-js" */)
}

